import React from "react"

import SEO from "../components/seo"
import Layout from '../layouts/findep'
import VerificacionDomicilio from '../components/pages/VerificacionDomicilio'
import GlobalFooterProvider from "../context/GlobalStateFooterContext"

const VerificacionDomicilioTemplate = ({pageContext}) => (
  <GlobalFooterProvider>
  <Layout companyName={pageContext.company} gradient >
    <SEO title={pageContext.title} />
    <VerificacionDomicilio pageContext={pageContext} /> 
  </Layout>
</GlobalFooterProvider>
)

export default VerificacionDomicilioTemplate